/******************************** Import Package ************************************/
import React from "react";
import { HighlightOff, CheckCircleOutline, FiberManualRecordTwoTone } from "@material-ui/icons";
import { dynamicId } from "../../../Utils/dynamicId";
import { displayUserRole, displayName,displayDateAndTime } from "../../../Utils/pillarFunctions";

export default function UserDetails({ recordForEdit,logInData }) {

  return (
    <table className="details-table">
      <tbody>
        <tr>
          <th> ID:</th>
          <td>{recordForEdit ? dynamicId("ur", recordForEdit.id) : "-"} </td>
        </tr>
        <tr>
          <th> Name:</th>
          <td className="capitalize">{recordForEdit ? displayName(recordForEdit) : "-"}
          </td>
        </tr>
        <tr>
          <th> Email:</th>
          <td >{recordForEdit ? recordForEdit.email : "-"} </td>
        </tr>
        <tr>
        <th> IP Address:</th>
          <td>{(logInData&& logInData.data && logInData.data.ip && logInData.data.ip.ip) ? logInData.data.ip.ip : "-"} </td>
        </tr>

        <tr>
        <th> Last Active:</th>
          <td>{(logInData && logInData.updatedAt) ?  displayDateAndTime(logInData.updatedAt) : "-"} </td>
        </tr>
        <tr>
          <th> Specialty:</th>
          <td >{recordForEdit ? recordForEdit.specialty : "-"} </td>
        </tr>
        <tr>
          <th> Title:</th>
          <td >{recordForEdit ? recordForEdit.title : "-"} </td>
        </tr>
        <tr>
          <th> Mobile:</th>
          <td >{recordForEdit ? recordForEdit.mobileNumber : "-"} </td>
        </tr>
        <tr>
          <th> Office Phone:</th>
          <td >{recordForEdit ? recordForEdit.officePhone : "-"} </td>
        </tr>
        <tr>
          <th> Pager:</th>
          <td>{recordForEdit ? recordForEdit.pager : "-"} </td>
        </tr>
        <tr>
          <th> Fax:</th>
          <td>{recordForEdit ? recordForEdit.fax : "-"} </td>
        </tr>

        <tr>
          <th> Active:</th>
          <td>{recordForEdit ? (recordForEdit.isActive ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : (
            <HighlightOff color="secondary" />
          )) : "-"} </td>
        </tr>
        <tr>
          <th> Primary Care:</th>
          <td>{recordForEdit ? (recordForEdit.pcp ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : (
            <HighlightOff color="secondary" />
          )) : "-"} </td>
        </tr>
      </tbody>
    </table >
  );
}
