
/******************************** Import Package ************************************/
import React, { useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { Drawer, AppBar, Toolbar, List } from "@material-ui/core";
import { CssBaseline, Divider } from "@material-ui/core";
import { ThemeProvider, IconButton, createMuiTheme } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import clsx from "clsx";

/******************************* Import Pages ************************************/
import Dashboard from "../Pages/Dashboard";
import NotFound from "../Pages/NotFound";
import Unauthorized from "../Pages/Unauthorized";
import Login from "../Pages/Login";
import TimeOut from "../Pages/TimeOut";
import GoogleAuth from "../Pages/GoogleAuth";
/******************************* Import Components *********************************/
import SideNavigate from "./SideNavigate";
import Header from "./Header";
// import Loader from "../Components/Loader";
import Notification from "../Components/Notification";
import Users from "../Pages/Admin/Users";
import LoggedInHistory from "../Pages/Admin/LoggedInHistory";
/******************************* Import Pages lazy load************************************/

/******************************* Import Constants **********************************/
import * as menuData from "../Constants/menuData";
import * as dashBoardData from "../Constants/dashboardData";
import * as accessLevel from "../Constants/accessLevel";
import { env, hostConfig, idleTime } from "../Config";
/******************************* Import Styles **********************************/
import { useAppStyles } from "./App.Style";
import "./App.css";

/**********************************Theme color****************************************/

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#9acad6',
      main: '#4e9a98',
      dark: '#87bcb4',
      contrastText: '#fff',
    },
  },
});

/***********************************App****************************************/

export default function App() {
  const classes = useAppStyles();
  const [open, setOpen] = useState(false);

  let roleData;
  let menuLinks;
  let dashBoardLinks;
  let role;
  let userData = {};

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const inactivityTime = () => {
    let time;

    const logout = () => {
      window.location.href = "/time-out"
    };

    const resetTimer = () => {
      clearTimeout(time);
      time = setTimeout(logout, idleTime);
    };
    window.onload = resetTimer;
    window.addEventListener("load", resetTimer, true);
    var events = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
      "touchmove",
      "wheel",
      "select",
    ];
    events.forEach(function (name) {
      document.addEventListener(name, resetTimer, true);
    });
  };

  window.onload = () => {
    inactivityTime();
  };


  /***********************************Find  User Role****************************************/

  if (JSON.parse(localStorage.getItem("loggedUser"))) {
    userData = JSON.parse(localStorage.getItem("loggedUser"));
    if (window.location.pathname === "/") {
      window.location.href = "/dashboard";
    }
  } else {
    const location = window.location.pathname;
    if (location !== "/login") {
      if (location === "/google/auth" || location === "/unauthorized") {

      } else {
        if (env === 4 || env === 2) {
          window.location.href = `${hostConfig.API_URL}auth/google`

        } else {
          window.location.href = "/login";
        }
      }
    }
  }

  /***********************************Response Handler****************************************/
  const responseHandler = (res) => {
    if (res) {
      if (res.code) {
        if (res.code === 400) {
          setNotify({
            isOpen: true,
            message: res.message,
            type: "error",
          });
          return false;
        } else {
          setNotify({
            isOpen: true,
            message: "Bad Request",
            type: "error",
          });
          return false;
        }
      } else if (res.error) {
        setNotify({
          isOpen: true,
          message: res.error,
          type: "error",
        });
        return false;
      } else {
        return res;
      }
    } else {
      return false;
    }
  };

  /***********************************Role Based Data****************************************/


  if ("admin") {
    roleData = accessLevel.adminRights;
    menuLinks = menuData.adminMenu;
    dashBoardLinks = dashBoardData.adminDashBoard;
    role = "admin";
  }

  const abc=[{name:"ghui",uya:4}]
  /***********************************Open Drawer****************************************/
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  /***********************************Close Drawer****************************************/
  const handleDrawerClose = () => {
    setOpen(false);
  };

  let UrlPath = window.location.pathname

  /***********************************Return App****************************************/
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className={`${classes.root} App`}>
          <CssBaseline />
          {userData.email ? (
            <AppBar
              position="fixed"
              style={{ paddingRight: "0px" }}
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar className={classes.customWidth} >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}>
                  <MenuIcon className={classes.menuCustomWidth} />
                </IconButton>
                <Header responseHandler={responseHandler} />
              </Toolbar>
            </AppBar>
          ) : null}

          {userData.email ? (
            <Drawer
              id="navigation-drawer"
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRight />
                  ) : (
                    <ChevronLeft />
                  )}
                </IconButton>
              </div>
              <List className={classes.sideMenu}>
                {menuLinks.map(item => (
                  <SideNavigate
                    key={item.id}
                    className={classes.sideMenuItem}
                    name={item.name}
                    link={item.link}
                    icon={item.icon}
                    open={open}
                  />
                )
                )}
              </List>
              <Divider />
            </Drawer>
          ) : null}
          <main className={classes.content} id="main">
            <Switch >
              {/*************************** * Genera Route *********************************************/}
              {/* <Route exact path="/">
                <Login responseHandler={responseHandler} />
              </Route> */}
              <Route path="/login">
                <Login responseHandler={responseHandler} />
              </Route>
              <Route path="/google/auth">
                <GoogleAuth />
              </Route>
              <Route path="/dashboard">
                <Dashboard dashBoardLinks={dashBoardLinks} dashBoardAccess={roleData.dashboard}
                  responseHandler={responseHandler} />
              </Route>

              <Route path="/unauthorized">
                <Unauthorized dashBoardLinks={dashBoardLinks} dashBoardAccess={roleData.dashboard}
                  responseHandler={responseHandler} />
              </Route>
              <Route path="/404">
                <NotFound />
              </Route>
              <Route path="/time-out">
                <TimeOut />
              </Route>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route path="/log-in-history">
                <LoggedInHistory userAccess={roleData.users} responseHandler={responseHandler} />
              </Route>
              <Route path="/users">
                <Users userAccess={roleData.users} responseHandler={responseHandler} />
              </Route>
              <Route path="*">
                <Redirect to="/404" />
                <NotFound />
              </Route>

            </Switch>
            <Notification notify={notify} setNotify={setNotify} />
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
}
