/******************************** Import Package ************************************/
import React from "react";

import userIcon from "../Assets/icons/userIcon.svg";


/******************************** Admin DashBoard************************************/
const adminDashBoard = [
 
  {
    id: "users",
    name: "Contacts",
    link: "users",
    icon: <img alt="User" src={userIcon} />,
  },
  // {
  //   id: "pcp",
  //   name: "Primary Care",
  //   link: "pcp",
  //   icon: <img alt="PCP" src={userIcon} />,
  // },
  // {
  //   id: "favorites",
  //   name: "Favourites",
  //   link: "favorites",
  //   icon: <img alt="User" src={userIcon} />,
  // },
];
 
export {
  adminDashBoard
};
