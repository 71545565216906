/******************************** Import libs ************************************/
import { getListByApi, viewDataByApi } from "./actions";
import { URL_CONSTANTS } from "./urls";

const getUserList = (params) => {
  return getListByApi(URL_CONSTANTS.users, params);
};

const getUserById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.users, dataId);
};

const getFavorites = (params) => {
  return getListByApi(URL_CONSTANTS.favorites, params);
}

const getFavoritesById = (dataId) => {
  return viewDataByApi(URL_CONSTANTS.favorites, dataId);
};

const getLocation = (params) => {
  return getListByApi(URL_CONSTANTS.location, params);
};

const getLogs = (params) => {
  return getListByApi(URL_CONSTANTS.logs, params);
};

const getLogsUpdate = (params) => {
  return getListByApi(URL_CONSTANTS.logsUpdate, params);
};

const getLogsUndo = (params) => {
  return getListByApi(URL_CONSTANTS.logsUndo, params);
};

const getActiveStatus = (params) => {
  return getListByApi(URL_CONSTANTS.activeStatus, params);
};

export {
  getUserList,
  getUserById,
  getFavorites,
  getFavoritesById,
  getLocation,
  getLogs,
  getLogsUpdate,
  getLogsUndo,
  getActiveStatus,
};
