/******************************** Import Package ************************************/

import React from "react";
import dashboardIcon from "../Assets/icons/dashboardIcon.svg";
import userIcon from "../Assets/icons/userIcon.svg";
import { Timeline } from '@material-ui/icons';

/******************************** Admin Menu************************************/
const adminMenu = [
  {
    id: "dashboard",
    name: "Dashboard",
    link: "dashboard",
    icon: <img alt="Dashboard" src={dashboardIcon} />,
  },

  {
    id: "users",
    name: "Contacts",
    link: "users",
    icon: <img alt="User" src={userIcon} />,
  },
  {
    id: "loginHistory",
    name: "Login History",
    link: "log-in-history",
    icon: <Timeline style={{ color: "white", fontSize: 30 }} />,
  },
  // {
  //   id: "pcp",
  //   name: "Primary Care",
  //   link: "pcp",
  //   icon: <img alt="PCP" src={userIcon} />,
  // },
  // {
  //   id: "favorites",
  //   name: "Favourites",
  //   link: "favorites",
  //   icon: <img alt="User" src={userIcon} />,
  // },
];

export {
  adminMenu,
};
