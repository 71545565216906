/******************************** Import Package ************************************/
import React, { useEffect } from "react";
import Controls from "../../Components/Controls";
import { Link } from "react-router-dom";
import "./TimeOut.css";
import { homeURL, stagingHomeURL, env, waitTimeInTimeOutScreen } from "../../Config";
const TimeOut = () => {
    useEffect(() => {
        let intervalId = setTimeout(() => {
            localStorage.clear();
            if (env === 4) {
                window.location.replace(homeURL);
            } else if (env === 2) {
                window.location.replace(stagingHomeURL)
            } else {
                window.location.href = "/login";
            }
        }, waitTimeInTimeOutScreen)
        return () => clearTimeout(intervalId);
    }, []);

    return (
        <div className="flex Timeout">
            <h3>This page is Idle Too Long  </h3>
            <p>What You want to do?</p>
            <Link to="/users" >
                <Controls.ActionButton variant="contained" color="primary" >
                    BACK TO CONTACTS
                </Controls.ActionButton >
            </Link>
            {/* <Controls.ActionButton variant="contained" color="primary" onClick={home}>
                BACK TO HOME
                </Controls.ActionButton > */}
        </div>
    );
};

export default TimeOut;
