export const getPayRequestStatus = () => [
  { id: "1", title: "approved" },
  { id: "2", title: "declined" },
  { id: "3", title: "pending" },
  { id: "4", title: "paid" },
];

export const getDurationCollection = () => [
  { id: "1", title: "1 Month" },
  { id: "2", title: "3 Months" },
  { id: "3", title: "6 Months" },
  { id: "4", title: "12 Months" },
  { id: "5", title: "Indefinite" },
];

export const getPayLevelCollection = () => [
  { id: "1", title: "Level 1" },
  { id: "2", title: "Level 2" },
  { id: "3", title: "Level 3" },
  { id: "4", title: "Level 4" },
];

export const getProjectType = () => [
  { id: "1", title: "Project" },
  { id: "2", title: "Committee" },
];

export const getRequestType = () => [
  { id: "all", title: "All" },
  { id: "sign_up", title: "Sign Up" },
  { id: "payment", title: "Payment" },
  { id: "add_hours", title: "Add Hours" },
  { id: "leave", title: "Leave" },
];

export const getRequestStatus = () => [
  { id: "all", title: "All" },
  { id: "approved", title: "Approved" },
  { id: "rejected", title: "Rejected" },
  { id: "pending", title: "Pending" },
  // { id: "paid", title: "Paid" },
];
export const getPaidStatus = () => [
  { id: "all", title: "All" },
  { id: "paid", title: "Paid" },
  { id: "notPaid", title: "Not Paid" },
];

export const levelOfEffort = () => [
  { id: "1", title: "1" },
  { id: "2", title: "2" },
  { id: "3", title: "3" },
];

export const getRoleCollection = () => [
  { id: "admin", title: "Administrator" },
  { id: "approver", title: "Approver" },
  { id: "sponsor", title: "Sponsor" },
  { id: "resource", title: "User" },
];

export const getActive=()=>[
  // { id: "all", title: "All" },
  { id: "true", title: "Active" },
  { id: "false", title: "Inactive" },
];

export const getPcp=()=>[
  { id: "all", title: "All" },
  { id: "pcp", title: "Primary Care" },
  { id: "notPcp", title: "Not Primary Care" },
];

export const getAdmin=()=>[
  { id: "all", title: "All" },
  { id: "admin", title: "Administrator" },
  { id: "notAdmin", title: "Not Administrator" },
];

export const getAllProjectType = () => [
  { id: "all", title: "All" },
  { id: "Project", title: "Project" },
  { id: "Committee", title: "Committee" },
];

export const getEmailList = [
  { id: "@farallonmed.com", title: "Farallon" },
  { id: "@pacificmedicalgroup.org", title: "Pacific Medical Group" },
];

export const allowedCharacters=["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z","1","2","3","4","5","6","7","8","9","0"," "];
