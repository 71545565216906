/******************************** Import Package ************************************/
import React from "react";
import { HighlightOff, CheckCircleOutline, FiberManualRecordTwoTone } from "@material-ui/icons";
import { dynamicId } from "../../../Utils/dynamicId";
import { displayUserRole, displayName } from "../../../Utils/pillarFunctions";

export default function UserDetails({ recordForEdit }) {
  return (
    <table className="details-table">
      <tbody>
        <tr>
          <th> ID:</th>
          <td>{dynamicId("ur", recordForEdit.id)} </td>
        </tr>
        <tr>
          <th> Name:</th>
          <td className="capitalize">{displayName(recordForEdit)}
          </td>
        </tr>
        <tr>
          <th> Email:</th>
          <td >{recordForEdit.email} </td>
        </tr>
        <tr>
          <th> Specialty:</th>
          <td >{recordForEdit.specialty} </td>
        </tr>
        <tr>
          <th> Title:</th>
          <td >{recordForEdit.title} </td>
        </tr>
        <tr>
          <th> Mobile:</th>
          <td >{recordForEdit.mobileNumber} </td>
        </tr>
        <tr>
          <th> Office Phone:</th>
          <td >{recordForEdit.officePhone} </td>
        </tr>
        <tr>
          <th> Pager:</th>
          <td>{recordForEdit.pager} </td>
        </tr>
        <tr>
          <th> Fax:</th>
          <td>{recordForEdit.fax} </td>
        </tr>

        <tr>
          <th> Active:</th>
          <td>{recordForEdit.isActive ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : (
            <HighlightOff color="secondary" />
          )} </td>
        </tr>
        <tr>
          <th> Primary Care:</th>
          <td>{recordForEdit.pcp ? (
            <CheckCircleOutline style={{ color: "green" }} />
          ) : (
            <HighlightOff color="secondary" />
          )} </td>
        </tr>
      </tbody>
    </table >
  );
}
