/******************************** Import Package ************************************/
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Controls from "../../../Components/Controls"
import { useForm, Form } from "../../../Components/useForm";
import Notification from "../../../Components/Notification";

const initialFValues = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  title: "",
  specialty: "",
  officePhone: "",
  pager: "",
  fax: "",
  notes: "",
  password: "",
  isAdmin: false,
  pcp: false,
};

export default function UserForm(props) {
  const { addOrEdit, recordForEdit } = props;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("firstName" in fieldValues) { temp.firstName = fieldValues.firstName ? "" : "Please Enter Your First Name "; }

    if ("lastName" in fieldValues) { temp.lastName = fieldValues.lastName ? "" : "Please Enter Your Last Name"; }

    if ("password" in fieldValues) {
      if (values.isAdmin) {
        if (fieldValues.password === "") { temp.password = "Please Enter Password"; }
        else if (fieldValues.password.length < 8) { temp.password = "Password must be minimum 8 characters long" }
        else if (fieldValues.password) { temp.password = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(fieldValues.password) ? "" : "Password must contain at least one letter and one number"; }
        else { temp.password = ""; }
      } else {
        temp.password = "";
      }
    }

    if ("email" in fieldValues) {
      if (!/$^|.+@.+..+/.test(fieldValues.email)) { temp.email = "Please Enter Valid Email"; }
      else { temp.email = ""; }
    }

    setErrors({ ...temp });

    if (fieldValues === values) { return Object.values(temp).every((x) => x === ""); }
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  } = useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      if (values.isAdmin) {

      } else {
        delete values.password;
      }
      addOrEdit(values, resetForm);
    }
  };

  const handleValueChange = (e) => {
    handleInputChange(e)
    const { name, value } = e.target;
    let temp = value
    if (name === "mobileNumber" || name === "officePhone" || name === "pager" || name === "fax") {
      let x = temp.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      temp = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    setValues({
      ...values,
      [name]: temp,
    });

  }
  useEffect(() => {
    if (recordForEdit != null) {
      setValues({
        ...recordForEdit,
      });
    }
  }, [recordForEdit, setValues]);


  return (
    <Form onSubmit={handleSubmit}>
      <Grid container  >
        <Grid item xs={12} sm={6} className="flex">
          <Controls.Input
            autoFocus
            name="firstName"
            label="First Name*"
            value={values.firstName || ""}
            onChange={handleValueChange}
            error={errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex">
          <Controls.Input
            name="lastName"
            label="Last Name*"
            value={values.lastName || ""}
            onChange={handleValueChange}
            error={errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex">
          <Controls.Input
            label="Email"
            name="email"
            value={values.email || ""}
            onChange={handleValueChange}
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex" >
          <Controls.Input
            label="Specialty"
            name="specialty"
            value={values.specialty || ""}
            onChange={handleValueChange}
            error={errors.specialty}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex" >
          <Controls.Input
            label="Title"
            name="title"
            value={values.title || ""}
            onChange={handleValueChange}
            error={errors.title}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex" >
          <Controls.Input
            label="Mobile"
            name="mobileNumber"
            value={values.mobileNumber || ""}
            onChange={handleValueChange}
            error={errors.mobileNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex" >
          <Controls.Input
            label="Office Phone"
            name="officePhone"
            value={values.officePhone || ""}
            onChange={handleValueChange}
            error={errors.officePhone}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex" >
          <Controls.Input
            label="Pager"
            name="pager"
            value={values.pager || ""}
            onChange={handleValueChange}
            error={errors.pager}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="flex" >
          <Controls.Input
            label="Fax"
            name="fax"
            value={values.fax || ""}
            onChange={handleValueChange}
            error={errors.fax}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="flex" >
          <Grid item xs={6} sm={5}>
            <Controls.Checkbox
              name="pcp"
              label="Primary Care"
              value={values.pcp || ""}
              onChange={handleValueChange}
            />
          </Grid>
          <Grid item xs={6} sm={5}>
            <Controls.Checkbox
              name="isAdmin"
              label="Administrator"
              value={values.isAdmin || ""}
              onChange={handleValueChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} className="flex multi-line-container" style={{ display: values.isAdmin ? "" : "none" }}>
          <Controls.Password
            label="Password*"
            name="password"
            value={values.password}
            onChange={handleValueChange}
            error={errors.password}
          />
        </Grid>

        <Grid item xs={12} sm={12} className="flex multi-line-container" >
          <Controls.Input
            className="multi-line-text multi-line-notes"
            rows={4}
            name="notes"
            label="Notes"
            value={values.notes || ""}
            onChange={handleValueChange}
            error={errors.notes}
            multiline
          />
        </Grid>

        <Grid item xs={false} sm={7} className="flex" >
        </Grid>
        <Grid item xs={12} sm={4} className="flex" justify='flex-end' style={{ marginRight: "1rem" }}>
          <Controls.Button text="Clear" color="secondary" onClick={resetForm} />
          <Controls.Button type="submit" text="Submit" />
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Form>
  );
}
