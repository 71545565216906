/******************************** Import Package ************************************/
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Controls from "./Controls";
import Close from "@material-ui/icons/Close";

/******************************** Popup style ************************************/
const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(1),
    width: "75vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
}));

/******************************** Popup ************************************/
export default function Popup(props) {
  const { title, children, openPopup, setOpenPopup } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={ openPopup }
      maxWidth="lg"
      classes={ { paper: classes.dialogWrapper } }
    >
      <DialogTitle className={ classes.dialogTitle }>
        <div style={ { display: "flex" } }>
          <Typography variant="h6" component="div" style={ { flexGrow: 1 } }>
            {title}
          </Typography>
          <Controls.ActionButton
            color="secondary"
            onClick={ () => {
              setOpenPopup(false);
            } }
          >
            <Close  style={{color:  "white"}}/>
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
