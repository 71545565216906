/******************************** Import Package ************************************/
import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

/******************************** Pagination ************************************/
export default function Pagination(props) {
  const {
    page,
    rowsPerPage,
    changePage,
    changeRowsPerPage,
    totalResults,
  } = props;
  const pages = [10,25, 50, 100];
  return (
    <TablePagination
      component="div"
      count={ totalResults }
      page={ page }
      onChangePage={ (event, newPage) => changePage(event, newPage) }
      rowsPerPageOptions={ pages }
      rowsPerPage={ rowsPerPage }
      onChangeRowsPerPage={ (event) => changeRowsPerPage(event) }
    />
  );
}
