/******************************** Import Package ************************************/
import React from "react";
import PageNotFound from "./not-found.png";
import Controls from "../../Components/Controls";
import "./NotFound.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="flex NotFound">
      <h3>ERROR 404! </h3>
      {/* <h3> PAGE NOT FOUND</h3> */}
      <p>There is no Page Here</p>
      <img src={PageNotFound} alt="Not Found" />
        <Link to="/dashboard" >
      <Controls.ActionButton variant="contained" color="primary">
          BACK TO DASHBOARD
      </Controls.ActionButton >
                </Link>
    </div>
  );
};

export default NotFound;
