import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
console.log(App)


ReactDOM.render(<App />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change

// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
