/******************************** Import Package ************************************/
import React, { useState, useEffect } from "react";

/******************************* Import icons **********************************/
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import { EditOutlined, Delete } from "@material-ui/icons";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import userIcon from "../../../Assets/icons/userIcon.svg";
import { FiberManualRecordTwoTone } from '@material-ui/icons';

/******************************* Import Components **********************************/
import SearchBar from "../../../Components/SearchBar";
import Loader from "../../../Components/Loader";
import useTable from "../../../Components/useTable";
import Pagination from "../../../Components/Pagination";
import Controls from "../../../Components/Controls";
import Popup from "../../../Components/Popup";
import Notification from "../../../Components/Notification";
import ConfirmDialog from "../../../Components/ConfirmDialog";

/****************************** Import Sub-Components *******************************/
import UserForm from "./UserForm";
import UserDetails from "./UserDetails";
import Filter from "../../../Components/FilterContacts/Filter";
/****************************** Import API ******************************************/
import { getUserList, getFavoritesById } from "../../../Api/list";
import { postUserData, postUserFilter } from "../../../Api/create";
import { updateUserData } from "../../../Api/update";
import { deleteUser } from "../../../Api/delete"

/****************************** Import Utils ****************************************/
import { displayDate } from "../../../Utils/common";
import { displayName, displayUserRole } from "../../../Utils/pillarFunctions";
import { refreshInterval } from "../../../Config";

/******************************** Import Images************************************/
import NotFound from "../../../Assets/images/no-records.jpg";

import { activeStatusCallTime } from "../../../Config";
import { getActiveStatus } from "../../../Api/list";

const headCells = [
  { id: "isLoggedIn", label: "Online", width: "4%", Sort: true },
  { id: "lastName", label: "Name", width: "25%", Sort: true },
  { id: "email", label: "Email", width: "25%", Sort: true },
  { id: "isAdmin", label: "Administrator", width: "5%" },
  { id: "pcp", label: "Primary Care", width: "5%" },
  { id: "createdAt", label: "Created On", Sort: true },
  { id: "actions", label: "Actions", width: "20%" },
];

export default function Users({ userAccess, responseHandler }) {
  const [users, setUsers] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openDetailsPopup, setOpenDetailsPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(null)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalResults, setTotalResults] = useState(0);
  const [searchField, setSearchField] = useState("");

  const [filterValues, setFilterValues] = useState("");
  const [fromFilter, setFromFilter] = useState(false);
  const [filterField, setFilterField] = useState(null);

  let restrict = !userAccess.edit && !userAccess.delete

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    getActiveStatus("")
    let intervalId = setInterval(() => {
      getActiveStatus("")
    }, activeStatusCallTime)
    return () => clearInterval(intervalId);
  }, []);

  const initialUserData = (search) => {
    let lastName = search ? search : null;
    let sortBy = "lastName:asc"
    let currentPage = page + 1;
    getUserList({ lastName, sortBy, currentPage, rowsPerPage }).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        setTotalResults(res.totalResults)
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    initialUserData()
  }, []);

  const getUserData = (latestPage, rows, title, sort) => {
    let currentPage = (latestPage === 0 || latestPage) ? latestPage + 1 : 0;
    let rowsPerPage = rows ? rows : null;
    let search = title ? title : null;
    let sortBy = sort ? sort : "lastName:asc";
    getUserList({ currentPage, rowsPerPage, title, search, sortBy }).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        setTotalResults(res.totalResults);
      }
    });
  };

  const postFilterData = (filters, latestPage, rows, searchValue, sortBy) => {
    let data = {
      // isActive: true,
      ...filters,
      limit: rows,
      page: latestPage + 1,
      search: searchValue,
      sortBy: sortBy
    }
    if (!data.limit) {
      delete data.limit
    }
    if (!data.page) {
      delete data.page
    }
    if (!data.search) {
      delete data.search
    }
    if (!data.sortBy) {
      data.sortBy = "lastName:asc"
    }
    postUserFilter(data).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        setTotalResults(res.totalResults);
      }
    });
  }

  const getFavoritesOfUser = (id) => {
    getFavoritesById(id).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        setTotalResults(res.totalResults);
      }
    });
  }

  const {
    TblContainer,
    TblHead,
    recordsAfterPagingAndSorting,
  } = useTable(users, headCells, { fn: items => items }, rowsPerPage);

  const doesUserExist = (data) => {
    return users.findIndex((x) => x.id === data.id) !== -1 ? true : false;
  };

  const addOrEdit = (user, resetForm) => {
    const existUser = doesUserExist(user);
    let error = false;
    if (user.id === 0 && existUser) {
      error = true;
    } else if (user.id === 0 && !existUser) {
      delete user.id;
      if (user.mobileNumber === "") {
        delete user.mobileNumber
      }
      postUserData(user).then((res) => {
        if (responseHandler(res)) {
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
          postFilterData(filterField, page, rowsPerPage, searchField, sortBy)

        }
      });
    } else {
      const updateId = user.id;
      delete user.id;
      delete user.createdAt;
      updateUserData(user, updateId).then((res) => {
        if (responseHandler(res)) {
          setNotify({
            isOpen: true,
            message: "Updated Successfully",
            type: "success",
          });
          postFilterData(filterField, page, rowsPerPage, searchField, sortBy)

        }
      });
    }
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
    if (error) {
      setNotify({
        isOpen: true,
        message: "Record Already Exist",
        type: "error",
      });
    }
  };
  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteUser(id).then((res) => {
      if (responseHandler(res)) {
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "success",
        });
        postFilterData(filterField, page, rowsPerPage, searchField, sortBy)

      }
    });
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const openInDetailsPopup = (item) => {
    setRecordForEdit(item);
    setOpenDetailsPopup(true);
  };

  const AddNewButton = () => {
    setOpenPopup(true);
    setRecordForEdit(null);
  };

  const searchAll = (data) => {
    setPage(0);
    setSearchField(data)
    if (data) {
      postFilterData(filterField, 0, rowsPerPage, data, sortBy)

    } else {
      postFilterData(filterField, 0, rowsPerPage, null, sortBy)
    }
  };

  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    fromFilter ? postFilterData(filterField, 0, event.target.value, searchField, sortBy) :
      getUserData(0, event.target.value, searchField, sortBy);
  };

  const changePage = (event, newPage) => {
    setPage(() => newPage);
    fromFilter ? postFilterData(filterField, newPage, rowsPerPage, searchField, sortBy) :
      getUserData(newPage, rowsPerPage, searchField, sortBy);
  };

  const getSortedList = (sortValue) => {
    setSortBy(sortValue)
    postFilterData(filterField, page, rowsPerPage, searchField, sortValue)
  }

  useEffect(() => {
    const interval = setInterval(() => { getUserData(page, rowsPerPage, searchField, sortBy); }, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval, page, rowsPerPage, searchField, sortBy])

  const handleFilter = (e) => {
    setPage(0)
    setFromFilter(true)
    const { name, value } = e.target;

    setFilterValues({ ...filterValues, [name]: value, });

    let data = { ...filterValues, [name]: value, }

    if (name === "email" && value.length < 1) {
      delete filterValues.email;
      delete data.email;
    }
    setFilterValues(data);
    let data2 = { ...data }
    if (data2.isAdmin) {
      if (data2.isAdmin === "all") {
        delete data2.isAdmin;
      } else {
        data2.isAdmin === "admin" ? data2.isAdmin = true : data2.isAdmin = false
      }
    }

    if (data2.pcp) {
      if (data2.pcp === "all") {
        delete data2.pcp;
      } else {
        data2.pcp === "pcp" ? data2.pcp = true : data2.pcp = false
      }
    }
    setFilterField(data2)
    postFilterData(data2, 0, rowsPerPage, searchField, sortBy)
  }

  const resetFilterData = () => {
    setFilterValues({})
    setFilterField(null)
    setFromFilter(false)
    setSearchField("")
    setPage(0)
    getUserData(0, rowsPerPage);
  }

  return (
    <>
      <SearchBar
        title="Contacts"
        icon={<img alt="User Icon" src={userIcon} />}
        label="Search User"
        isAdmin={userAccess.add}
        AddNew={AddNewButton}
        handleData={searchAll}
        searchField="lastName"
      />
      <Filter
        handleFilter={handleFilter}
        resetFilterData={resetFilterData}
        filterValues={filterValues}
      />

      <div className="table-container">
        {loading ? (
          <Loader loading />
        ) : (
          recordsAfterPagingAndSorting().length > 0 ?
            <TblContainer>
              <TblHead getSortedList={getSortedList} restrict={restrict} />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {item.isLoggedIn ? (
                        <FiberManualRecordTwoTone style={{ color: "green" }} />
                      ) : (
                        <FiberManualRecordTwoTone color="secondary" />
                      )}
                    </TableCell>
                    <TableCell className="minimum-character capitalize" onClick={() => openInDetailsPopup(item)}>
                      {displayName(item)}
                    </TableCell>
                    <TableCell
                      className="minimum-character"
                      onClick={() => openInDetailsPopup(item)}
                    >
                      {item.email}
                    </TableCell>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {item.isAdmin ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <HighlightOff color="secondary" />
                      )}
                    </TableCell>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {item.pcp ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <HighlightOff color="secondary" />
                      )}
                    </TableCell>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {displayDate(item.createdAt)}
                    </TableCell>
                    {userAccess.edit || userAccess.delete ? (
                      <TableCell>
                        {userAccess.edit ?
                          <Controls.ActionButton
                            color="primary"
                            onClick={() => {
                              openInPopup(item);
                            }}
                          >
                            <EditOutlined fontSize="small" />
                          </Controls.ActionButton>
                          : null}

                        {userAccess.delete ?
                          <Controls.ActionButton
                            color="secondary"
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: "Are you sure?",
                                subTitle: "You can't undo this operation",
                                onConfirm: () => {
                                  onDelete(item.id);
                                },
                              });
                            }}
                          >
                            <Delete fontSize="small" style={{ color: "white" }} />
                          </Controls.ActionButton>
                          : null}
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            : <div className="flex no-records">
              <TblContainer>  <TblHead getSortedList={getSortedList} restrict={restrict} /></TblContainer>
              <img src={NotFound} alt="No Records Found" />
              <h3>No Records Found</h3>
              <hr />
            </div>
        )}
      </div>

      <Pagination
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={totalResults}
        changePage={(event, newPage) => changePage(event, newPage)}
        changeRowsPerPage={(event) => changeRowsPerPage(event)}
      />

      <Popup
        title="User Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <UserForm recordForEdit={recordForEdit} addOrEdit={addOrEdit} />
      </Popup>

      <Popup
        title="User Details"
        openPopup={openDetailsPopup}
        setOpenPopup={setOpenDetailsPopup}
      >
        <UserDetails recordForEdit={recordForEdit} />
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
