/******************************** Import Package ************************************/
import React , {useState} from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Menu, MenuItem, IconButton } from "@material-ui/core";
import Notification from "../Components/Notification";

import loggedUserIcon from "../Assets/icons/loggedUserIcon.svg";
import logoIcon from "../Assets/icons/logo.svg";
import { ExitToApp } from "@material-ui/icons";

import { getUserSessionData } from "../Utils/session";
import { displayUserRole } from "../Utils/pillarFunctions";
import { env, homeURL , stagingHomeURL} from "../Config";
import { postLogoutRequestData } from "../Api/create";
import { getRefreshToken } from "../Utils/session";

/******************************** Header Stye ************************************/
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "white",
    color: "black",
    marginRight: "0",
    width: "100vw",
    padding: "0",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {},
  title: {
    flexGrow: 1,
  },
}));

/************************************ Header  ************************************/
export default function Header({ responseHandler }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    let refreshToken = getRefreshToken();
    if (refreshToken) {
      let data = { refreshToken: refreshToken };
      postLogoutRequestData(data).then((res) => {

      });
    }
    localStorage.removeItem("accessExpiry");
    localStorage.removeItem("refreshExpiry");
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("currentUser");
    if (env === 4) {
      window.location.replace(homeURL); 
    }else if (env === 2) {
      window.location.replace(stagingHomeURL);       
    }  else {
      window.location.href = "/login";
    }
  };

  let userData = getUserSessionData();

  return (
    <div className = {`${classes.root} header` } >
      <div className="header-img">
        <img src={logoIcon} alt="logo" />
      </div>
      <div className="header-title">Pingya</div>
      <div className="flex-row">
        <span className="text-right">
          <p> {userData.email}</p>
          <p className="capitalize"> {userData.isAdmin?"Administrator":"User"}</p>
        </span>

        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <img src={loggedUserIcon} alt="logged user" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            style={{
              color: "#f50057",
            }}
            onClick={handleLogout}
          >
            Logout <ExitToApp />
          </MenuItem>
        </Menu>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
