/******************************** Import Package ************************************/
import React, { useState } from "react";
import { Table, TableHead, TableRow } from "@material-ui/core";
import { TableCell, makeStyles } from "@material-ui/core";
import { TableSortLabel } from "@material-ui/core";
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  container: {
    maxHeight: 440,
  },
  table: {
    "& thead th": {
      fontWeight: "600",
      color: "white",
      backgroundColor: "#4e9a98",
    },
    "& tbody td": {
      fontWeight: "400",
       padding: ".5rem",
       height:"3.5rem",
    },
    "& tbody tr:hover": {
      backgroundColor: "#9acad6 ",
      cursor: "pointer",
    },
  },
}));

export default function useTable(records, headCells) {
  const classes = useStyles();
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const TblContainer = (props) => (
    <div  className={classes.root}>
    <TableContainer className={classes.container}>
    <Table stickyHeader aria-label="sticky table" className={classes.table}>{props.children}</Table>
    </TableContainer>
    </div>
  );

  const TblHead = ({getSortedList,restrict}) => {

    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(cellId);
      let dataToSort= `${cellId}:${isAsc ? "desc" : "asc"}`
      getSortedList(dataToSort)
    };
  
   return (
      <TableHead className="table-header">
        <TableRow >
          {headCells.map((headCell) => (
            headCell.id==="actions" && restrict?null:
           <TableCell  className="minimum-character" 
            key={ headCell.id }
            width={ headCell.width }
              sortDirection={ orderBy === headCell.id ? order : false }
            >
              { 
              headCell.Sort ? (
                <TableSortLabel className="label" 
                  active={ orderBy === headCell.id }
                direction={ orderBy === headCell.id ? order : "asc" }
                onClick={ () => {handleSortRequest(headCell.id)} }
              >
                {headCell.label}
              </TableSortLabel>
              ) 
              : (headCell.label)
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
  
  const recordsAfterPagingAndSorting = () => {
    return records
  };

  return {
    TblContainer,
    TblHead,
    recordsAfterPagingAndSorting,
  }
}
