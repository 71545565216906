/******************************** Import Package ************************************/
import React, { useState } from "react";
import Controls from "../Controls";
import "./Filter.css";
import { Grid } from "@material-ui/core";
import {  getPcp,getAdmin,getEmailList} from "../../Constants";

/******************************** Filter  ************************************/
const Filter = (props) => {
  const { filterValues, handleFilter,   resetFilterData } = props;
  return (
    <Grid container className="right-align filter-91">
      <Grid item xs={12} sm={6}  md={3} lg={3} className="filter-91"> 
        <Controls.MultiSelectId
        className="min-size-8"
          variant="outlined"
          name="email"
          label="Email "
          value={filterValues.email || []}
          onChange={handleFilter}
          options={getEmailList}
        />
      </Grid>

      <Grid item xs={12} sm={6}  md={3} lg={3} className="filter-91">
        <Controls.Select
          className="min-size-8"
          name="pcp"
          label="Primary Care"
          value={filterValues.pcp ||"all"}
          onChange={handleFilter}
          options={getPcp()}
          valuesOnly={true}
        />
      </Grid>

      <Grid item xs={12} sm={6}  md={3} lg={3} className="filter-91">
        <Controls.Select
          className="min-size-8"
          name="isAdmin"
          label="Administrator"
          value={filterValues.isAdmin ||"all"}
          onChange={handleFilter}
          options={getAdmin()}
          valuesOnly={true}
        />
      </Grid>

      <Grid item xs={12} sm={6}  md={1} lg={1}>
        <Controls.Button text="Clear" color="secondary" onClick={resetFilterData} />
      </Grid>
    </Grid>
  );
};
export default Filter;
