/******************************** Import libs ************************************/

const URL_CONSTANTS = {

  users: "admin/users",
  usersFilter: "admin/users/filter",
  favorites: "favourites",

  login: "auth/login",
  register: "auth/register",
  logout: "auth/logout",
  refreshTokens: "auth/refresh-tokens",

  location: "location",
  
  logs: "logs",
  logsUpdate: "updateLogs",
  logsUndo: "updateLogs/undo",
  activeStatus: "users/activeStatus",
};

export { URL_CONSTANTS };
