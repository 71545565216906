/******************************** Import Package ************************************/
import React, { useState, useEffect } from "react";

/******************************* Import icons **********************************/
import { CheckCircleOutline, HighlightOff } from "@material-ui/icons";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import userIcon from "../../../Assets/icons/userIcon.svg";
import { FiberManualRecordTwoTone } from '@material-ui/icons';

/******************************* Import Components **********************************/
import SearchBar from "../../../Components/SearchBar";
import PageHeader from "../../../Components/PageHeader";
import Loader from "../../../Components/Loader";
import useTable from "../../../Components/useTable";
import Pagination from "../../../Components/Pagination";
import Controls from "../../../Components/Controls";
import Popup from "../../../Components/Popup";
import Notification from "../../../Components/Notification";
import ConfirmDialog from "../../../Components/ConfirmDialog";

/****************************** Import Sub-Components *******************************/
import UserDetails from "./UserDetails";
import Filter from "../../../Components/FilterContacts/Filter";
/****************************** Import API ******************************************/
import { getLogs } from "../../../Api/list";
/****************************** Import Utils ****************************************/
import { displayDate } from "../../../Utils/common";
import { displayName, displayUserRole } from "../../../Utils/pillarFunctions";
import { refreshInterval } from "../../../Config";

/******************************** Import Images************************************/
import NotFound from "../../../Assets/images/no-records.jpg";

import { activeStatusCallTime } from "../../../Config";
import { getActiveStatus } from "../../../Api/list";

const headCells = [
  { id: "isLoggedIn", label: "Online", width: "10%"},
  { id: "lastName", label: "Name", width: "25%" },
  { id: "email", label: "Email", width: "25%" },
  { id: "isAdmin", label: "Administrator", width: "5%" },
  { id: "pcp", label: "Primary Care", width: "5%" },
  { id: "updatedAt", label: "Last Active", Sort: true },
];

export default function Users({ userAccess, responseHandler }) {
  const [users, setUsers] = useState([]);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openDetailsPopup, setOpenDetailsPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState(null)

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [totalResults, setTotalResults] = useState(0);
  const [searchField, setSearchField] = useState("");
  const [logInData, setLogInData] = useState({});

  useEffect(() => {
    getActiveStatus("")
    let intervalId = setInterval(() => {
      getActiveStatus("")
    }, activeStatusCallTime)
    return () => clearInterval(intervalId);
  }, []);

  let restrict = !userAccess.edit && !userAccess.delete

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });


  const initialUserData = (search) => {
    let lastName = search ? search : null;
    // let sortBy = "lastName:asc"
    let currentPage = page + 1;
    let action = "login"
    getLogs({ lastName, sortBy, currentPage, rowsPerPage, action }).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        setTotalResults(res.totalResults)
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    initialUserData()
  }, []);

  const getUserData = (latestPage, rows, title, sort) => {
    let currentPage = (latestPage === 0 || latestPage) ? latestPage + 1 : 0;
    let rowsPerPage = rows ? rows : null;
    let search = title ? title : null;
    let action = "login"
    // let sortBy = sort ? sort : "lastName:asc";
    getLogs({ currentPage, rowsPerPage, title, search, sortBy, action }).then((res) => {
      if (responseHandler(res)) {
        setUsers(res.results);
        setTotalResults(res.totalResults);
      }
    });
  };

  const {
    TblContainer,
    TblHead,
    recordsAfterPagingAndSorting,
  } = useTable(users, headCells, { fn: items => items }, rowsPerPage);


  const openInDetailsPopup = (item) => {
    setLogInData(item)
    setRecordForEdit(item.userId);
    setOpenDetailsPopup(true);
  };


  const changeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getUserData(0, event.target.value, searchField, sortBy);
  };

  const changePage = (event, newPage) => {
    setPage(() => newPage);
    getUserData(newPage, rowsPerPage, searchField, sortBy);
  };

  const getSortedList = (sortValue) => {
    setSortBy(sortValue)
    getUserData(page, rowsPerPage, searchField, sortBy);
  }

  useEffect(() => {
    const interval = setInterval(() => { getUserData(page, rowsPerPage, searchField, sortBy); }, refreshInterval);
    return () => clearInterval(interval);
  }, [refreshInterval, page, rowsPerPage, searchField, sortBy])

  return (
    <>
      <PageHeader title="Login History" icon={<img alt="User Icon" src={userIcon} />} />

      <div className="table-container">
        {loading ? (
          <Loader loading />
        ) : (
          recordsAfterPagingAndSorting().length > 0 ?
            <TblContainer>
              <TblHead getSortedList={getSortedList} restrict={restrict} />
              <TableBody>
                {recordsAfterPagingAndSorting().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {item.userId ? (item.userId.isLoggedIn ? (
                        <FiberManualRecordTwoTone style={{ color: "green" }} />
                      ) : (
                        <FiberManualRecordTwoTone color="secondary" />
                      )) : "-"}
                    </TableCell>
                    <TableCell className="minimum-character capitalize" onClick={() => openInDetailsPopup(item)}>
                      {item.userId ? displayName(item.userId) : "-"}
                    </TableCell>
                    <TableCell
                      className="minimum-character"
                      onClick={() => openInDetailsPopup(item)}
                    >
                      {item.userId ? item.userId.email : "-"}
                    </TableCell>
                    <TableCell onClick={() => openInDetailsPopup(item ? item.userId : "-")}>
                      {item.userId ? (item.userId.isAdmin ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <HighlightOff color="secondary" />
                      )) : "-"}
                    </TableCell>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {item.userId ? (item.userId.pcp ? (
                        <CheckCircleOutline style={{ color: "green" }} />
                      ) : (
                        <HighlightOff color="secondary" />
                      )) : "-"}
                    </TableCell>
                    <TableCell onClick={() => openInDetailsPopup(item)}>
                      {item.userId ? displayDate(item.updatedAt) : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </TblContainer>
            : <div className="flex no-records">
              <TblContainer>  <TblHead getSortedList={getSortedList} restrict={restrict} /></TblContainer>
              <img src={NotFound} alt="No Records Found" />
              <h3>No Records Found</h3>
              <hr />
            </div>
        )}
      </div>

      <Pagination
        page={page}
        rowsPerPage={rowsPerPage}
        totalResults={totalResults}
        changePage={(event, newPage) => changePage(event, newPage)}
        changeRowsPerPage={(event) => changeRowsPerPage(event)}
      />

      <Popup
        title="User Details"
        openPopup={openDetailsPopup}
        setOpenPopup={setOpenDetailsPopup}
      >
        <UserDetails recordForEdit={recordForEdit} logInData={logInData}/>
      </Popup>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
