/******************************** Import Package ************************************/
import React from "react";
import Card from "../../Components/Card/Card";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "./DisplayDashboard.css";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0px",
  },
 }));

export default function DashBoard({ dashBoardLinks, dashboardData, dashBoardAccess,userCount }) {
  const classes = useStyles()

  return (
    <>
      <Grid container className={`${classes.root} card-container`} direction="row"
  justify="center"  alignItems="center">
        {dashBoardLinks.map((item) => {
          return (
            <Card
              key={item.id}
              name={item.name}
              value={userCount}
              // value={dashboardData[item.id]}
              icon={item.icon}
              link={item.link}
              userCount={userCount}
            />
          );
        })}
      </Grid>
    </>
  );
}
