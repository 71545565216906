/******************************** Import Package ************************************/
import React from "react"
import { Paper, Card, Typography, makeStyles } from "@material-ui/core"

/******************************** PageHeader styles  ************************************/
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "initial"
    },
    pageHeader:{
        padding:theme.spacing(4),
        display:"flex",
    },
    pageIcon:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        padding:theme.spacing(2),
        backgroundColor:"#4e9a98"
    },
    pageTitle:{
        paddingLeft:theme.spacing(4),
        "& .MuiTypography-subtitle2":{
            opacity:"0.6"
        }
    }
}))

/******************************** PageHeader   ************************************/
export default function PageHeader(props) {

    const classes = useStyles();
    const { title, subTitle, icon } = props;
    return (
        <Paper elevation={ 0 } square className={ classes.root }>
            <div className={`${classes.pageHeader} pageHeader` }>
                <Card className={`${classes.pageIcon} pageIcon` }>
                    {icon}
                </Card>
                <div className={ classes.pageTitle }>
                    <Typography
                        variant="h6"
                        component="div">
                        {title}</Typography>
                    <Typography
                        variant="subtitle2"
                        component="div">
                        {subTitle}</Typography>
                </div>
            </div>
        </Paper>
    )
}
