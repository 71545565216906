export const dynamicId = (initial, id) => {
if (id){
    let digitsOfNumbers = 3;
    let length = id.toString().length
    let formattedNumber=id
    
    if (length <= digitsOfNumbers) {
        let zero="0";
        for (; length < digitsOfNumbers; length++) {
           
            formattedNumber=  (zero + formattedNumber.toString())
        }
    }
    let initialValue = initial

    switch (initial.toLowerCase()) {
        case "pl":
            initialValue = "PL"
            break;

        case "gl":
            initialValue = "GL"
            break;

        case "pj":
            initialValue = "PJ"
            break;

        case "sr":
            initialValue = "SR"
            break;

        case "pr":
            initialValue = "PR"
            break;

        case "po":
            initialValue = "PO"
            break;

        case "ur":
            initialValue = "UR"
            break;

        default:
            initialValue = initial
            break;
    }
    let finalId = initialValue + formattedNumber.toString();
    return finalId;
}
return 0;

}
