/******************************** Import Package ************************************/
import React, {useState} from "react";
import { Search ,Close} from "@material-ui/icons";
import { makeStyles, Toolbar, InputAdornment } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
/******************************** Import Libs ************************************/
import PageHeader from "./PageHeader";
import Controls from "./Controls";

/******************************** Import Constants ************************************/
import {allowedCharacters}  from "../Constants";

/************************ Styles **************************************************/
const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "0.625rem",
  },
  closeButton:{
    cursor:"pointer",   
    
  }
}));
// ************************main components***********************
const SearchBar = ({ isAdmin, AddNew, title, icon, handleData, proposeProject, proposeBtn}) => {
  // label
  const classes = useStyles();
  const [search,setSearch]=useState("");

  const blockSpecialChar =(title)=>{ 
    let  lastChar  = title.substr(title.length - 1); 
if (allowedCharacters.includes(lastChar)){
  setSearch(title)
  console.log(title)
  handleData(title);
  return true
}else{
  setSearch(title.slice(0, -1))
}
}

  const handleSearch = (e) => {
    let title = e.target.value;
    blockSpecialChar(title)
  };

  const clear=()=>{
    setSearch("")
    handleData("");
  }


  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td style={{ width: "30%" }}>
            <PageHeader title={title} icon={icon} />
          </td>
          <td style={{ width: "70%" }}>
            <Toolbar>
              <Controls.Input
                autoFocus
                // label={ label }
                value={search}
                className={classes.searchInput}
                onChange={(e) => handleSearch(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment:(search.length>0?<InputAdornment position="end"><Close  className={classes.closeButton} onClick={clear}
                  /></InputAdornment>:null),
                }}
              />

              {proposeBtn ?
                <Controls.Button
                  text="Propose Project"
                  variant="outlined"
                  // startIcon={ <AddIcon /> }
                  className={classes.newButton}
                  onClick={proposeProject}
                />
                : null}

              {isAdmin ? (

                <Controls.Button
                  text="Add New"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className={classes.newButton}
                  onClick={() => AddNew()}
                />
              ) : null}
            </Toolbar>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
export default SearchBar;
